<template>
  <div>
    <sequential-entrance fromTop>
      <SellerHeader to="/seller/menu" headerText="Mis categorias" />
    </sequential-entrance>
    <v-main>
      <v-container>
        <v-tabs grow>
          <v-tab href="#myproducts">
            Mis productos VIP
          </v-tab>

          <v-tab-item value="myproducts" class="mt-3">
            <v-row justify="center">
              <v-col cols="12" class="pt-0 pb-0 mt-3">
                <v-text-field
                  label="Buscar categoria por nombre"
                  v-model="filter"
                  color="#00B8D9"
                  flat
                  background-color="#F4F5F7"
                  class="app-input pt-0"
                ></v-text-field>
              </v-col>
            </v-row>

            {{ filter }}

            <v-row v-if="categoriesLoading == false && categories.length">
              <v-col cols="12">
                <transition name="slide-fade">
                  <v-container class="app-list">
                    <v-row
                      v-for="(category, index) in categoryFilter"
                      class="align-center mb-5 rounded-lg blue lighten-4"
                      :key="index"
                      @click="goToProductCategoryId(category)"
                    >
                      <v-col cols="7">
                        <p
                          class="
                            mb-0
                            font-weight-bold
                            text-color-soft-black
                          "
                        >
                          {{ category.name }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </transition>
              </v-col>
            </v-row>

            <!-- Skeleton Loader -->
            <v-row v-if="categoriesLoading">
              <v-container
                v-for="(skeleton, index) in skeletonCount"
                class="align-center d-flex justify-content-between rounded-lg mb-5"
                style="background-color: #FAFAFA!important"
                justify="between"
                :key="index"
              >
                <v-col cols="10">
                  <b-skeleton width="60%"></b-skeleton>
                  <b-skeleton width="90%"></b-skeleton>
                </v-col>

                <v-col cols="2" class="pl-0 text-center">
                  <b-skeleton type="avatar"></b-skeleton>
                </v-col>
              </v-container>
            </v-row>
          </v-tab-item>
          <v-tab href="#allproducts" :disabled="editProductDisabled">
            Listado VIP
          </v-tab>
          <v-tab-item value="allproducts" class="mt-5">
            <v-row>
              <v-col cols="12">
                <transition name="slide-fade">
                  <v-container class="app-list">
                    <v-row
                      v-for="(product, index) in orderedProducts"
                      class="mb-5 d-flex justify-content-between rounded-lg"
                      style="background-color: #FAFAFA!important"
                      justify="between"
                      :key="index"
                    >
                      <v-col cols="8">
                        <div @click="goToProductDetail(product)">
                          <p
                            class="
                              mb-0
                              font-weight-bold
                              text-color-soft-black
                            "
                            style="font-size: 14px !important"
                          >
                            {{ product.id }} - {{ product.name }}
                          </p>

                          <p
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            <strong>Categoría: </strong
                            >{{ product.category.name }}
                          </p>

                          <p
                            class="
                              mb-0
                              font-weight-bold
                              text-color-blue
                            "
                            style="font-size: 14px !important"
                          >
                            $ {{ product.price }}
                          </p>

                          <p
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            <span
                              class="dot"
                              style="background-color: green"
                            ></span
                            >Válido desde
                            {{ dateToHours(product.initial_date) }} hasta
                            {{ dateToHours(product.finish_date) }}
                          </p>

                          <p
                            v-if="product.tolerance == null"
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            <span
                              class="dot"
                              style="background-color: yellow"
                            ></span
                            >Sin tolerancia
                          </p>

                          <p
                            v-if="product.tolerance != null"
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            <span
                              class="dot"
                              style="background-color: yellow"
                            ></span
                            >Tolerancia: {{ product.tolerance }} minutos
                          </p>

                          <p
                            v-if="
                              product.comision > 0 &&
                                product.comision_type == 'percentage'
                            "
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            Comisión: {{ product.comision }}%
                          </p>

                          <p
                            v-if="
                              product.comision > 0 &&
                                product.comision_type == 'fixed'
                            "
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            Comisión: $ {{ product.comision }}
                          </p>

                          <p
                            v-if="product.comision == 0 || !product.comision"
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            Sin comisión
                          </p>

                          <p
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            Evento: {{ product.event.name }}
                          </p>

                          <p
                            v-if="product.min_age && product.min_age != 0"
                            style="font-size: 11px !important"
                            class="mb-0 text-color-soft-black"
                          >
                            Producto habilitado para
                            <strong
                              >mayores de {{ product.min_age }} años</strong
                            >
                          </p>

                          <p
                            class="mt-3 text-color-soft-black"
                            style="font-size: 12px"
                          >
                            <strong>
                              Vendedores ({{
                                product.dataSellers.length
                              }}):&nbsp;
                            </strong>
                            <span
                              v-for="(seller, asd) in product.dataSellers"
                              :key="asd"
                            >
                              {{ seller.fullname }},&nbsp;
                            </span>
                          </p>

                          <p
                            v-if="product.reference"
                            class="mt-1 text-color-soft-black"
                            style="font-size: 12px"
                          >
                            <strong>
                              Referencia:
                            </strong>
                            {{ product.reference }}
                          </p>
                        </div>
                      </v-col>

                      <v-col
                        cols="4"
                        class="text-center d-flex flex-column justify-content-between"
                      >
                        <div class="d-flex justify-content-end">
                          <b-dropdown
                            size="lg"
                            right
                            variant="link"
                            no-caret
                            toggle-class="text-decoration-none"
                          >
                            <template #button-content>
                              <v-icon size="32px">
                                mdi-dots-vertical
                              </v-icon>
                            </template>
                            <b-dropdown-item
                              @click="goToEditMaxQuote(product.id)"
                            >
                              <v-icon>
                                mdi-ticket-confirmation-outline
                              </v-icon>
                              &nbsp; Editar capacidad máxima
                            </b-dropdown-item>

                            <b-dropdown-divider></b-dropdown-divider>

                            <b-dropdown-item
                              @click="goToEditProductPrice(product.id)"
                            >
                              <v-icon>
                                mdi-currency-usd
                              </v-icon>
                              &nbsp; Editar precio
                            </b-dropdown-item>

                            <b-dropdown-divider></b-dropdown-divider>

                            <b-dropdown-item
                              @click="goToEditProductSellers(product.id)"
                            >
                              <v-icon>
                                mdi-account-edit
                              </v-icon>
                              &nbsp; Editar vendedores
                            </b-dropdown-item>

                            <b-dropdown-divider></b-dropdown-divider>

                            <b-dropdown-item
                              @click="goToEditProduct(product.id)"
                            >
                              <v-icon>
                                mdi-pencil
                              </v-icon>
                              &nbsp; Editar producto
                            </b-dropdown-item>

                            <b-dropdown-divider></b-dropdown-divider>

                            <b-dropdown-item
                              @click="$bvModal.show(`modal-${product.id}`)"
                            >
                              <v-icon>
                                mdi-content-duplicate
                              </v-icon>
                              &nbsp; Clonar producto
                            </b-dropdown-item>

                            <b-dropdown-divider></b-dropdown-divider>

                            <b-dropdown-item
                              @click="
                                $bvModal.show(`disable-modal-${product.id}`)
                              "
                            >
                              <v-icon>
                                mdi-cancel
                              </v-icon>
                              &nbsp; Deshabilitar producto
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>

                        <div class="d-flex justify-content-center">
                          <v-row class="pt-2 justify-content-center">
                            <v-col cols="6" class="px-1 pt-4">
                              <b-progress :max="product.max_quote">
                                <b-progress-bar
                                  v-if="
                                    product.max_quote <=
                                      product.total_tickets_active
                                  "
                                  variant="danger"
                                  :value="ocupate(product.total_tickets_active)"
                                  :label="
                                    `
                                    ${(
                                      (ocupate(product.total_tickets_active) /
                                        product.max_quote) *
                                      100
                                    ).toFixed()}%`
                                  "
                                ></b-progress-bar>
                                <b-progress-bar
                                  v-else
                                  :value="ocupate(product.total_tickets_active)"
                                  :label="
                                    `${(
                                      (ocupate(product.total_tickets_active) /
                                        product.max_quote) *
                                      100
                                    ).toFixed()}%`
                                  "
                                ></b-progress-bar>
                              </b-progress>
                            </v-col>

                            <v-col cols="6" class="px-0">
                              <v-chip
                                color="font-weight-bold"
                                x-small
                                style="background-color: #FAFAFA!important"
                              >
                                <span
                                  :class="[
                                    product.total_tickets_active >=
                                      product.max_quote ||
                                    product.sell_simple > 0
                                      ? 'text-color-gray'
                                      : 'text-color-blue',
                                  ]"
                                  >{{
                                    ocupate(product.total_tickets_active)
                                  }}/{{ product.max_quote }}</span
                                >
                              </v-chip>
                            </v-col>

                            <v-chip
                              color="font-weight-bold"
                              small
                              style="background-color: #FAFAFA!important"
                              class="px-0 text-center"
                            >
                              <span
                                >{{
                                  product.assistants_verified
                                }}
                                Verificados</span
                              >
                            </v-chip>
                          </v-row>
                        </div>

                        <div
                          class="d-flex justify-content-center"
                          style="height: 50px!important"
                        ></div>
                      </v-col>

                      <!-- Clone Modal -->
                      <b-modal
                        ref="clone-modal"
                        :id="'modal-' + product.id"
                        centered
                        title="Clonar producto"
                        header-bg-variant="dark"
                        header-text-variant="light"
                        hide-footer
                      >
                        <div class="text-center">
                          <p class="font-italic">
                            ¿Estás seguro que deseas clonar el producto
                            <strong>{{ product.name }}</strong
                            >?
                          </p>
                          <b-button
                            class="mx-3"
                            variant="success"
                            @click="
                              cloneProduct(product.id)
                              $bvModal.hide(`modal-${product.id}`)
                            "
                            >Clonar producto</b-button
                          >
                        </div>
                      </b-modal>

                      <!-- Disable product Modal -->
                      <b-modal
                        ref="disable-modal"
                        :id="'disable-modal-' + product.id"
                        centered
                        title="Deshabilitar producto"
                        header-bg-variant="dark"
                        header-text-variant="light"
                        hide-footer
                      >
                        <div class="text-center">
                          <p class="font-italic">
                            ¿Estás seguro que deseas deshabilitar el producto
                            <strong>{{ product.name }}</strong
                            >?
                          </p>
                          <p>
                            La capacidad máxima pasará de ser
                            {{ product.max_quote }} cupos a
                            <strong
                              >{{ product.total_tickets_active }} cupos</strong
                            >
                          </p>
                          <b-button
                            class="mx-3"
                            variant="success"
                            @click="
                              disableProduct(product.id)
                              $bvModal.hide(`disable-modal-${product.id}`)
                            "
                            >Deshabilitar producto</b-button
                          >
                        </div>
                      </b-modal>
                    </v-row>

                    <!-- Skeleton Loader -->
                    <v-row v-if="loading">
                      <v-container
                        v-for="(skeleton, index) in skeletonCount"
                        class="align-center d-flex justify-content-between rounded-lg mb-5"
                        style="background-color: #FAFAFA!important"
                        justify="between"
                        :key="index"
                      >
                        <v-col cols="10">
                          <b-skeleton width="60%"></b-skeleton>
                          <b-skeleton width="90%"></b-skeleton>
                          <b-skeleton width="50%"></b-skeleton>
                          <b-skeleton width="30%"></b-skeleton>
                        </v-col>

                        <v-col cols="2" class="pl-0 text-center">
                          <b-skeleton type="avatar"></b-skeleton>
                        </v-col>
                      </v-container>
                    </v-row>
                  </v-container>
                </transition>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getCategoriesBySeller,
  getCategoriesBySellerVip,
  getProductsOfEventVip,
  getProductSellers,
} from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'
import { getMyData } from '@/services/user.service'
import {
  getProductSellersVIP,
  cloneProduct,
  disableProduct,
} from '@/services/seller.service'
import { isoDateToHour } from '@/helpers/date'
import _ from 'lodash'

export default {
  name: 'SellerCategoryList',

  data() {
    return {
      filter: null,
      categories: [],
      allProducts: [],
      editProductDisabled: null,
      skeletonCount: [0, 1, 2, 3],
      loading: false,
      categoriesLoading: null,
    }
  },
  computed: {
    categoryFilter() {
      return this.categories.filter(category =>
        this.filter
          ? category?.name.toLowerCase().includes(this.filter.toLowerCase())
          : category
      )
    },
    orderedProducts: function() {
      return _.orderBy(this.allProducts, 'id', 'desc')
    },
  },
  methods: {
    goToProductDetail(product) {
      this.$router.push({
        name: 'Detalle del producto vip',
        params: { productId: product.uuid },
      })
    },
    goToProductCategoryId({ id: categoryId }) {
      localStorage.setItem('ticket-qr-category', categoryId)

      this.$router.push({
        name: 'Mis productos Vip',
        params: { categoryId },
      })
    },
    goToEditProduct(productId) {
      const jwt = localStorage.getItem('access_token')

      window.location.href =
        process.env.VUE_APP_ADMIN_DOMAIN +
        '/products/' +
        productId +
        '?jwt=' +
        jwt
    },
    goToEditProductSellers(productId) {
      const jwt = localStorage.getItem('access_token')
      window.location.href =
        process.env.VUE_APP_ADMIN_DOMAIN +
        '/products/' +
        productId +
        '/users' +
        '?jwt=' +
        jwt
    },
    goToEditProductPrice(productId) {
      const jwt = localStorage.getItem('access_token')
      window.location.href =
        process.env.VUE_APP_ADMIN_DOMAIN +
        '/products/' +
        productId +
        '/price' +
        '?jwt=' +
        jwt
    },
    goToEditMaxQuote(productId) {
      const jwt = localStorage.getItem('access_token')
      window.location.href =
        process.env.VUE_APP_ADMIN_DOMAIN +
        '/products/' +
        productId +
        '/max_quote' +
        '?jwt=' +
        jwt
    },
    showModal() {
      this.$refs['clone-modal'].show()
    },
    async cloneProduct(id) {
      const response = await cloneProduct(id)

      if (response.status == '200') {
        Vue.$toast.success(`Producto clonado correctamente!`)
        this.getProductsOfEventVip()
      } else {
        Vue.$toast.error('Ha ocurrido un error clonando el producto')
      }
    },
    async disableProduct(id) {
      const response = await disableProduct(id)

      if (response.status == '200') {
        Vue.$toast.success(`Producto deshabilitado correctamente!`)
        this.getProductsOfEventVip()
      } else {
        Vue.$toast.error('Ha ocurrido un error deshabilitando el producto')
      }
    },
    dateToHours(isoDate) {
      return isoDateToHour(isoDate)
    },
    ocupate(totalTicket) {
      return totalTicket || 0
    },
    async getcategories() {
      try {
        this.categoriesLoading = true

        const category = localStorage.getItem('selectedEvent')
        const data = await getCategoriesBySellerVip(category)

        this.categories = data.data

        this.categories.unshift({
          name: 'Todos los productos',
          id: -1,
        })

        localStorage.setItem(
          'ticket-qr-categories',
          JSON.stringify(this.categories)
        )

        this.categoriesLoading = false
      } catch (error) {
        this.$toast.error(ErrorMessages.COMMON)
      }
    },
    async getProductsOfEventVip() {
      try {
        // Si el usuario tiene permiso para edición de productos
        if (!this.editProductDisabled) {
          this.loading = true
          const eventId = localStorage.getItem('selectedEvent')
          const data = await getProductsOfEventVip(eventId, 1)
          const productsArray = data.data
          this.allProducts = []
          // Asignar vendedores del producto al producto
          productsArray.map(async (product, index) => {
            // Filtrar por productos activos unicamente
            if (product.active == 1) {
              await getProductSellers(product.id).then(res => {
                const dataSellers = res.data.data.data
                const source = { dataSellers: res.data.data.data }
                Object.assign(product, source)
                this.allProducts.push(product)
              })
            }
          })
          this.loading = false
        }
      } catch (e) {
        this.$toast.error(ErrorMessages.COMMON)
        console.error(e)
      }
    },
    async getMyData() {
      try {
        const { data } = await getMyData()
        this.editProductDisabled = data.product_edit == 1 ? false : true
      } catch (e) {
        console.error(e)
      }
    },
  },
  components: {
    SellerHeader,
  },
  async mounted() {
    if (localStorage.getItem('ticket-qr-categories') != null) {
      let data = []
      data = localStorage.getItem('ticket-qr-categories')
      this.categories = JSON.parse(data)
    }
    await this.getMyData()
    await this.getcategories()
    await this.getProductsOfEventVip()
  },
}
</script>

<style lang="scss">
.v-slide-group__prev {
  display: none !important;
}

.icon {
  cursor: pointer;
  display: block;
}
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
</style>
